import { DataType } from './dataType';

export class Payment extends DataType {
	id: number;
	accountName: string;
	application: string;
	service: string;
	accountNumber: string;
	address: string;
	city: string;
	transactionCode: string;
	date: string;
	paid: number;
	receiptId: number;

	constructor(object: Object = {}){ 
		var properties = ['id','accountName','application','service','accountNumber','address','city','transactionCode','date','paid','receiptId'];
		super(object, properties);
	}
}