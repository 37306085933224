import { DataType } from './dataType';
import { ReceivableAccountType } from './receivableAccountType';
import { ReceivableAccount } from './receivableAccount';
import { Customer } from './customer';

export class ReceivableBillingSummary extends DataType {
  accountId: number;
  account: ReceivableAccount;
  customerId: number;
  customer: Customer;
  billDate: string;
  dueDate: string;
  status: string;
  accountTypeId: number;
  accountType: ReceivableAccountType;
  beginningBalance: number;
  newCharges: number;
  endingBalance: number;
  jobId: number;

  constructor(object: Object = {}) {
    let properties = [
      'accountId',
      'account',
      'customerId',
      'customer',
      'billDate',
      'dueDate',
      'status',
      'accountTypeId',
      'accountType',
      'biginningBalance',
      'newCharges',
      'endingBalance',
      'jobId'
    ];
    super(object, properties);
  }
}
