import { DataType } from './dataType';

export class Tender extends DataType {
	id: number;
	code: string;
	description: string;
	isActive: boolean;
	requireMemo: boolean;
	memoLabel: string;
	isCashDeposit: boolean;
	isCheckDeposit: boolean;
 	reconcileCountAtClose: boolean;
 	isMerchantCredit: boolean;
 	isMerchantCheck: boolean;

	constructor(object: Object = {}){ 
		var properties = [
			'id',
			'code',
			'description',
			'isActive',
			'requireMemo',
			'memoLabel',
			'isCashDeposit',
			'isCheckDeposit',
			'reconcileCountAtClose',
			'isMerchantCredit',
			'isMerchantCheck'];
			
		super(object, properties);
	}
}