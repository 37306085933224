import { DataType } from './dataType';

export class User extends DataType {
	id: number;
	email: string;
	firstName: string;
	lastName: string;
	lastLoginDate: string;
	dateCreated: string;
	dateUpdated: string;
	password: string;
	passwordResetRequired: boolean;

	constructor(object: Object = {}){ 
		var properties = ['id','email','firstName','lastName','lastLoginDate','password','passwordResetRequired'];
		super(object, properties);
	}
}