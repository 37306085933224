import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";

import { ResourceService, DataService, ApiService } from "../services/services";

import { AppSettings } from "../settings";

@Injectable()
export class AttachmentsResource extends ResourceService {
  constructor(
    public dataService: DataService,
    private apiService: ApiService,
    private http: HttpClient,
    private appSettings: AppSettings
  ) {
    super(dataService, "attachments");
  }

  init(params) {
    this.url = "attachments";

    if (params.urlModifiers) {
      let modifiers = params.urlModifiers;

      if (modifiers.opportunityId) {
        this.url = "opportunities/" + modifiers.opportunityId + "/attachments";
      }

      if (modifiers.businessId) {
        this.url = "businesses/" + modifiers.businessId + "/attachments";
      }

      if (modifiers.receivableAccountId) {
        this.url =
          "receivableaccounts/" +
          modifiers.receivableAccountId +
          "/attachments";
      }

      if (modifiers.customerId) {
        this.url = "customers/" + modifiers.customerId + "/attachments";
      }

      if (modifiers.userId) {
        this.url = "users/" + modifiers.userId + "/attachments";
      }

      if (modifiers.file) {
        this.url += "/" + modifiers.attachmentId + "/file";
      }
    }
  }

  upload(params) {
    return new Promise((resolve, reject) => {
      this.init(params);

      var formData = new FormData();

      for (let p in params) {
        if (p != "urlModifiers") {
          formData.append(p, params[p]);
        }
      }

      params.file.percentUploaded = 0;

      const url =
        this.appSettings.apiServer +
        this.appSettings.apiUrl +
        this.dataService.getUrl(this.url, params);

      this.apiService.getHeader().then((options: any) => {
        options.reportProgress = true;
        options.observe = "events";

        this.http.post(url, formData, options).subscribe((event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            params.file.percentUploaded = Math.round(
              (100 * event.loaded) / event.total
            );
          } else if (event instanceof HttpResponse) {
            setTimeout(() => {
              resolve(event);
            });
          } else if (event.constructor.name == "HttpHeaderResponse") {
            setTimeout(() => {
              resolve(event);
            });
          }
        });
      });
    });
  }
}
