import { DataType } from './dataType';
import { Attachment } from './attachment';
import { OpportunityType } from './opportunityType';
import { DocumentType } from './documentType';
import { OpportunityTypeDocumentType } from './opportunityTypeDocumentType';

export class OpportunityAttachment extends DataType {
	opportunityId: number;
    attachmentId: number;
    attachment: Attachment;
    opportunityTypeId: number;
    opportunityType: OpportunityType;
    documentTypeId: number;
    documentType: DocumentType;
    logInRequired: boolean;
    opportunityTypeDocumentTypeId: number;
    opportunityTypeDocumentType: OpportunityTypeDocumentType;
    
	constructor(object: Object = {}){ 
		var properties = [
							'opportunityId',
                            'attachmentId',
                            'attachment',
                            'opportunityTypeId',
                            'opportunityType',
                            'documentTypeId',
                            'documentType',
							'logInRequired',
                            'opportunityTypeDocumentTypeId',
                            'opportunityTypeDocumentType'];
		super(object, properties);
	}
}
