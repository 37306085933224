import { DataType } from './dataType';
import { Application } from './application';

export class Fee extends DataType {
	id: number;
	name: string;
	amount: number;
	description: string;
	basedOnQuantity: boolean; //allowed to override qty
	allowOverride: boolean; //tied to price
	debitAccount: string;
	creditAccount: string;
	applicationId: string;
	application: Application;
	status: string;
	singleCharge: boolean;
	calculationMethod: string;
	excludeFromRenewals: boolean;
	excludeFromPenalties: boolean;
	isLateFee: boolean;


	constructor(object: Object = {}){ 
		var properties = [
			'id',
			'name',
			'amount',
			'description',
			'basedOnQuantity',
			'allowOverride',
			'debitAccount',
			'creditAccount',
			'applicationId',
			'application',
			'status',
			'singleCharge',
			'calculationMethod',
			'excludeFromRenewals',
			'excludeFromPenalties',
			'isLateFee'
		];
		super(object, properties);
	}
}