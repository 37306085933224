import { DataType } from './dataType';
import { Application } from './application';
import { Fee } from './fee';
import { Receipt } from './receipt';

export class ReceiptFee extends DataType {
	id: number;
	receiptId: number;
	receipt: Receipt;
	feeId: number;
	fee: Fee;
	amount: number;
	receiptTenderId: number;

	constructor(object: Object = {}){ 
		var properties = [
			'id',
			'receiptId',
			'receipt',
			'feeId',
			'fee',
			'amount',
			'receiptTenderId'
		];
		super(object, properties);
	}
}