import { DataType } from './dataType';

export class Pet extends DataType {
	id: number;
	name: string;

	constructor(object: Object = {}){ 
		var properties = [
            'id',
            'name',
            'species',
            'breed',
            'dateOfBirth',
            'sex',
            'altered',
            'color',
            'rabiesTagNumber',
            'rabiesTagExpirationDate',
            'veterinarian',
            'contactId',
            'contact',
            'createdAt',
            'updatedAt',
            'updatedByUserId',
            'udatedByUser',
        ];
		super(object, properties);
	}
}
