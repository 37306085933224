import { DataType } from './dataType';

export class ContactType extends DataType {
	id: number;
	code: string;
	description: string;
	status: string;

	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'code',
							'description',
							'status'];
		super(object, properties);
	}
}