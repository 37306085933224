import { DataType } from './dataType';
import { BankAccount } from './bankAccount';

export class Deposit extends DataType {
	id: number;
	dateCreated: string;
	datePrinted: string;
	userId: number;
	bankAccountId: string;
	bankAccount: BankAccount;

	constructor(object: Object = {}){ 
		var properties = ['id','dateCreated','datePrinted','userId','bankAccountId','bankAccount'];
		super(object, properties);

		if(!object['bankAccount']){
			this.bankAccount = new BankAccount();
		}
	}
}