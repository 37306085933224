import { Injectable } from "@angular/core";

import { ResourceService, DataService } from "../services/services";

@Injectable()
export class AgingStepsResource extends ResourceService {
  constructor(public dataService: DataService) {
    super(dataService, "agingstepslist");
  }

  init(params) {
    this.url = "agingstepslist";

    if (params.urlModifiers) {
      let modifiers = params.urlModifiers;

      if (modifiers.agingGroupId) {
        this.url = "aginggroups/:agingGroupId/agingsteps";
      }
    }
  }
}
