import { Injectable } from '@angular/core';

import { 
	ResourceService,
	DataService
} from '../services/services';

@Injectable()
export class BankAccountsResource extends ResourceService {

	constructor(public dataService: DataService) { 
		super(dataService, 'banks/:bankId/accounts');
	}
}