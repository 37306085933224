import { Injectable } from '@angular/core';

import {
	ResourceService,
	DataService
} from '../services/services';

@Injectable()
export class JobOutputsResource extends ResourceService {

	constructor(public dataService: DataService) {
		super(dataService, 'joboutputs');
	}

	init(params) {
    this.url = 'joboutputs';

    if (params.urlModifiers) {
      const modifiers = params.urlModifiers;

      if (modifiers.file) {
        this.url += '/' + modifiers.id + '/file';
      }
    }
  }
}
