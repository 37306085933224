import { DataType } from './dataType';

export class EventTypeSettings extends DataType {
	recordEvent: boolean;
	archiveAfter: string;

	constructor(object: Object = {}){ 
		var properties = [
			'recordEvent',
			'archiveAfter',
        ];
			
		super(object, properties);
	}
}