import { DataType } from './dataType';
import { Address } from './address';

export class PermitDate extends DataType {
	id: number;
	date: string;

	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'date'];
		super(object, properties);
	}
}