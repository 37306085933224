import { DataType } from './dataType';

export class Adjustment extends DataType {
	id: number;
	amount: number;
	comments: string;
	userId: number;

	constructor(object: Object = {}){ 
		var properties = ['id','amount','comments','userId'];
		super(object, properties);
	}
}