import { DataType } from './dataType';

export class BatchReconciliation extends DataType {
	id: number;
	batchId: number;
	oneDollarBills: number;
	fiveDollarBills: number;
	tenDollarBills: number;
	twentyDollarBills: number;
	fiftyDollarBills: number;
	oneHundredDollarBills: number;
	pennyCoins: number;
	nickelCoins: number;
	dimeCoins: number;
	quarterCoins: number;
	halfDollarCoins: number;
	dollarCoins: number;

	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'batchId',
							'oneDollarBills',
							'fiveDollarBills',
							'tenDollarBills',
							'twentyDollarBills',
							'fiftyDollarBills',
							'oneHundredDollarBills',
							'pennyCoins',
							'nickelCoins',
							'dimeCoins',
							'quarterCoins',
							'halfDollarCoins',
							'dollarCoins'];
		super(object, properties);

	}
}