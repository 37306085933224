import { DataType } from './dataType';
import { User } from './user';
import { ReceiptItem } from './receiptItem';

export class GeneralLedgerEntryItem extends DataType {
	id: number;
	source: string;
	receiptLineId: number;
	receiptLine: ReceiptItem;
	generalLedgerAccount: string;
	debitAmount: string;
	creditAmount: string;
	entryDate: string;
	entryUserId: number;
	entryUser: User;
	status: string;
	postDate: string;
	postUserId: number;
	postUser: User
	generalLedgerEntryHeaderId: number;

	constructor(object: Object = {}){ 
		var properties = [
			'id',
			'source',
			'receiptLineId',
			'receiptLine',
			'generalLedgerAccount',
			'debitAmount',
			'creditAmount',
			'entryDate',
			'entryUserId',
			'entryUser',
			'status',
			'postDate',
			'postUserId',
			'postUser',
			'generalLedgerEntryHeaderId'
		];
		super(object, properties);

		if(!object['entryUser']){
			this.entryUser = new User();
		}

		if(!object['postUser']){
			this.postUser = new User();
		}

		if(!object['postUser']){
			this.receiptLine = new ReceiptItem();
		}
	}
}