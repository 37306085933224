import { DataType } from "./dataType";
import { Address } from "./address";
import { Naics } from "./naics";
import { OwnershipType } from "./ownershipType";

export class Business extends DataType {
  id: number;
  number: number;
  name: string;
  doingBusinessAs: string;
  physicalAddress: Address;
  mailingAddress: Address;
  ownershipTypeId: number;
  ownershipType: OwnershipType;
  northAmericanIndustryClassificationSystemCodeId: number;
  northAmericanIndustryClassificationSystemCode: Naics;
  stateRegistrationNumber: string;
  referenceNumber: string;
  phone: string;
  fax: string;
  website: string;
  zoningCodeId: string;
  taxIdetificationNumber: string;
  pin: string;
  status: string;
  licenseCount: number;
  receivableAccountCount: number;
  opportunityCount: number;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "number",
      "name",
      "doingBusinessAs",
      "physicalAddress",
      "mailingAddress",
      "ownershipTypeId",
      "northAmericanIndustryClassificationSystemCodeId",
      "northAmericanIndustryClassificationSystemCode",
      "stateRegistrationNumber",
      "referenceNumber",
      "phone",
      "fax",
      "website",
      "zoningCodeId",
      "taxIdetificationNumber",
      "pin",
      "status",
      "licenseCount",
      "receivableAccountCount",
      "opportunityCount"
    ];
    super(object, properties);

    if (!object["physicalAddress"]) {
      this.physicalAddress = new Address();
    }

    if (!object["mailingAddress"]) {
      this.mailingAddress = new Address();
    }
  }
}
