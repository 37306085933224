import { NgModule, ModuleWithProviders, Optional } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { DataService } from './services/services';
import { MockDataService } from './services/services';
import { ApiService } from './services/services';
import { CurrentTownService } from './services/services';

import { ApplicationsResource } from './resources/applications.resource';
import { UsersResource } from './resources/users.resource';
import { UserRolesResource } from './resources/userRoles.resource';
import { UserCitiesResource } from './resources/userCities.resource';
import { UserPermissionsResource } from './resources/userPermissions.resource';
import { AccessTokenResource } from './resources/accessToken.resource';
import { RolesResource } from './resources/roles.resource';
import { PermissionsResource } from './resources/permissions.resource';
import { ScopesResource } from './resources/scopes.resource';
import { CashDrawersResource } from './resources/cashDrawers.resource';
import { ReceiptsResource } from './resources/receipts.resource';
import { ReceiptTendersResource } from './resources/receiptTenders.resource';
import { ReceiptTendersListResource } from './resources/receiptTendersList.resource';
import { PaymentsResource } from './resources/payments.resource';
import { ItemsResource } from './resources/items.resource';
import { DepositsResource } from './resources/deposits.resource';
import { DepositBatchListResource } from './resources/depositBatchList.resource';
import { GeneralLedgerEntriesResource } from './resources/generalLedgerEntries.resource';
import { GeneralLedgerEntryItemsResource } from './resources/generalLedgerEntryItems.resource';
import { BatchesResource } from './resources/batches.resource';
import { BatcheReconciliationResource } from './resources/batchReconciliation.resource';
import { ReconciliationListResource } from './resources/reconciliationList.resource';
import { BatchReceiptsResource } from './resources/batchReceipts.resource';
import { ReceiptItemsResource } from './resources/receiptItems.resource';
import { ReceiptLineListResource } from './resources/receiptLineList.resource';
import { ContactsResource } from './resources/contacts.resource';
import { ContactTypesResource } from './resources/contactTypes.resource';
import { FeesResource } from './resources/fees.resource';
import { BusinessesResource } from './resources/businesses.resource';
import { BusinessContactsResource } from './resources/businessContacts.resource';
import { BusinessLicenseListResource } from './resources/businessLicenseList.resource';
import { OwnershipTypesResource } from './resources/ownershipTypes.resource';
import { LicensesResource } from './resources/licenses.resource';
import { BusinessLicensesResource } from './resources/businessLicense.resource';
import { LicenseTypesResource } from './resources/licenseTypes.resource';
import { LicenseTypeFeesResource } from './resources/licenseTypeFees.resource';
import { LicenseTypeDocumentTypesResource } from './resources/licenseTypeDocumentTypes.resource';
import { LicenseFeesResource } from './resources/licenseFees.resource';
import { CitiesResource } from './resources/cities.resource';
import { CityApplicationsResource } from './resources/cityApplications.resource';
import { LocationsResource } from './resources/locations.resource';
import { ZoningCodesResource } from './resources/zoningCodes.resource';
import { GarageSalePermitsResource } from './resources/garageSalePermits.resource';
import { PermitFeesResource } from './resources/permitFees.resource';
import { PermitPartiesResource } from './resources/permitParties.resource';
import { PermitDatesResource } from './resources/permitDates.resource';
import { PaymentSourcesResource } from './resources/paymentSources.resource';
import { TimeZonesResource } from './resources/timeZones.resource';
import { StatesResource } from './resources/states.resource';
import { TendersResource } from './resources/tenders.resource';
import { AdjustmentsResource } from './resources/adjustments.resource';
import { NaicsResource } from './resources/naics.resource';
import { CategoriesResource } from './resources/categories.resource';
import { BanksResource } from './resources/banks.resource';
import { BankAccountsResource } from './resources/bankAccounts.resource';
import { KeyToCityResource } from './resources/keyToCity.resource';
import { LicenseRenewalSettingsResource } from './resources/licenseRenewalSettings.resource';
import { LicenseTypeRenewalsResource } from './resources/licenseTypeRenewals.resource';
import { LicenseTypeRenewalHistoryResource } from './resources/licenseTypeRenewalHistory.resource';
import { CountriesResource } from './resources/countries.resource';
import { MerchantSettingsResource } from './resources/merchantSettings.resource';
import { TenderFeesResource } from './resources/tenderFees.resource';
import { ReceiptFeesResource } from './resources/receiptFees.resource';
import { AttendeeRolesResource } from './resources/attendeeRoles.resource';
import { MinutesTemplatesResource } from './resources/minutesTemplates.resource';
import { MeetingItemTypesResource } from './resources/meetingItemTypes.resource';
import { MeetingTypesResource } from './resources/meetingTypes.resource';
import { MeetingTypeOutlinesResource } from './resources/meetingTypeOutlines.resource';
import { MeetingTypeAttendeesResource } from './resources/meetingTypeAttendees.resource';
import { DocumentTypesResource } from './resources/documentTypes.resource';
import { BusinessCertificationTypesResource } from './resources/businessCertificationTypes.resource';
import { ContactCertificationTypesResource } from './resources/contactCertificationTypes.resource';
import { OpportunityTypesResource } from './resources/opportunityTypes.resource';
import { OpportunityTypeDocumentTypesResource } from './resources/opportunityTypeDocumentTypes.resource';
import { OpportunitiesResource } from './resources/opportunities.resource';
import { EventTypesResource } from './resources/eventTypes.resource';
import { EventTypeSettingsResource } from './resources/eventTypeSettings.resource';
import { EventsResource } from './resources/events.resource';
import { AttachmentsResource } from './resources/attachments.resource';
import { PetsResource } from './resources/pets.resource';
import { PetLicensesResource } from './resources/petLicenses.resource';
import { OpportunityAttachmentsResource } from './resources/opportunityAttachments.resource';
import { BusinessAttachmentsResource } from './resources/businessAttachments.resource';
import { JobsResource } from './resources/jobs.resource';
import { JobOutputsResource } from './resources/jobOutputs.resource';
import { CustomersResource } from './resources/customers.resource';
import { CustomerAttachmentsResource } from './resources/customerAttachments.resource';
import { ReceivableAccountTypesResource } from './resources/receivableAccountTypes.recource';
import { ReceivableAccountsResource } from './resources/receivableAccounts.resource';
import { ReceivableAccountAttachmentsResource } from './resources/receivableAccountAttachments.resource';
import { ChargeTypesResource } from './resources/chargeTypes.resource';
import { ChargeTypeAmountsResource } from './resources/chargeTypeAmounts.resource';
import { AgingGroupsResource } from './resources/agingGroups.resource';
import { AgingStepsResource } from './resources/agingSteps.resource';
import { ReceivableChargesResource } from './resources/receivableCharges.resource';
import { RecurringChargeCriteriaResource } from './resources/recurringChargeCriteria.resource';
import { ReceivableBillingHistoriesResource } from './resources/receivableBillingHistories.resource';
import { ReceivableBillingPromptsResource } from './resources/receivableBillingPrompts.resource';
import { ReceivableBillingSummariesResource } from './resources/receivableBillingSummaries.resource';
import { ReceivableBillingDetailsResource } from './resources/receivableBillingDetails.resource';
import { RpcResource } from './resources/rpc.resource';

import { AppSettings } from './settings';
import { LicensePenaltiesResource } from './resources/licensePenalties.resource';
import { LicensePenaltyCriteriaResource } from './resources/licensePenaltyCriteria.resource';
import { BusinessLicensePenaltyHistoriesResource } from './resources/businessLicensePenaltyHistories.resource';
import { LicenseFeeListResource } from './resources/licenseFeeList.resource';
import { LicenseCertificateSettingsResource } from './resources/licenseCertificateSettings.resource';
import { ProjectsResource } from './resources/projects.resource';
import { AccountGroupsResource } from './resources/accountGroups.resource';
import { EntitiesResource } from './resources/entities.resource';

@NgModule({
  imports: [HttpClientModule],
})
export class ThorApiModule {
  static forRoot(config: any): ModuleWithProviders {
    return {
      ngModule: ThorApiModule,
      providers: [
        { provide: 'config', useValue: config },
        AppSettings,
        DataService,
        MockDataService,
        ApiService,
        CurrentTownService,

        ApplicationsResource,
        UsersResource,
        UserRolesResource,
        UserCitiesResource,
        UserPermissionsResource,
        AccessTokenResource,
        RolesResource,
        PermissionsResource,
        ScopesResource,
        CashDrawersResource,
        ReceiptsResource,
        ReceiptTendersResource,
        ReceiptTendersListResource,
        PaymentsResource,
        ItemsResource,
        DepositsResource,
        DepositBatchListResource,
        GeneralLedgerEntriesResource,
        GeneralLedgerEntryItemsResource,
        BatchesResource,
        BatcheReconciliationResource,
        ReconciliationListResource,
        BatchReceiptsResource,
        ReceiptItemsResource,
        ReceiptLineListResource,
        ContactsResource,
        CustomersResource,
        CustomerAttachmentsResource,
        ContactTypesResource,
        FeesResource,
        BusinessesResource,
        BusinessContactsResource,
        BusinessLicenseListResource,
        OwnershipTypesResource,
        LicensesResource,
        BusinessLicensesResource,
        LicenseTypesResource,
        LicenseTypeFeesResource,
        LicenseTypeDocumentTypesResource,
        LicenseFeesResource,
        CitiesResource,
        CityApplicationsResource,
        LocationsResource,
        ZoningCodesResource,
        GarageSalePermitsResource,
        PermitFeesResource,
        PermitPartiesResource,
        PermitDatesResource,
        PaymentSourcesResource,
        TimeZonesResource,
        StatesResource,
        TendersResource,
        AdjustmentsResource,
        NaicsResource,
        CategoriesResource,
        BanksResource,
        BankAccountsResource,
        KeyToCityResource,
        LicenseRenewalSettingsResource,
        LicenseTypeRenewalsResource,
        LicenseTypeRenewalHistoryResource,
        CountriesResource,
        MerchantSettingsResource,
        TenderFeesResource,
        ReceiptFeesResource,
        AttendeeRolesResource,
        MinutesTemplatesResource,
        MeetingItemTypesResource,
        MeetingTypesResource,
        MeetingTypeOutlinesResource,
        MeetingTypeAttendeesResource,
        DocumentTypesResource,
        BusinessCertificationTypesResource,
        ContactCertificationTypesResource,
        OpportunityTypesResource,
        OpportunityTypeDocumentTypesResource,
        OpportunitiesResource,
        EventTypesResource,
        EventTypeSettingsResource,
        EventsResource,
        AttachmentsResource,
        OpportunityAttachmentsResource,
        BusinessAttachmentsResource,
        PetsResource,
        PetLicensesResource,
        JobsResource,
        JobOutputsResource,
        ReceivableAccountTypesResource,
        ReceivableAccountsResource,
        ReceivableAccountAttachmentsResource,
        ChargeTypesResource,
        ChargeTypeAmountsResource,
        AgingGroupsResource,
        AgingStepsResource,
        ReceivableChargesResource,
        RecurringChargeCriteriaResource,
        ReceivableBillingHistoriesResource,
        ReceivableBillingPromptsResource,
        ReceivableBillingSummariesResource,
        ReceivableBillingDetailsResource,
        RpcResource,
        LicensePenaltiesResource,
        LicensePenaltyCriteriaResource,
        BusinessLicensePenaltyHistoriesResource,
        LicenseFeeListResource,
        LicenseCertificateSettingsResource,
        ProjectsResource,
        AccountGroupsResource,
        EntitiesResource
      ],
    };
  }
}
