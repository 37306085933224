import { DataType } from './dataType';
import { Application } from './application';
import { Tender } from './tender';
import { Receipt } from './receipt';

export class ReceiptTender extends DataType {
	id: number;
	receiptId: number;
	receipt: Receipt;
	tenderId: number;
	tender: Tender;
	amount: number;
	memo: string;
	confirmationNumber: string;
	refundTransactionNumber: string;
	refunded: boolean

	constructor(object: Object = {}){ 
		var properties = [
			'id',
			'receiptId',
			'receipt',
	    	'tenderId',
	    	'tender',
	    	'amount',
	    	'memo',
	    	'confirmationNumber',
	    	'refundTransactionNumber',
	    	'refunded'
		];
		super(object, properties);
	}
}