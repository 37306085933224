import { DataType } from './dataType';
import { Business } from './business';
import { Attachment } from './attachment';
import { User } from './user';

export class BusinessAttachment extends DataType {
	id: number;
    businessId: number;
    business: Business;
    attachmentId: number;
    attachment: Attachment;
    expirationDate: string;
    createdByUserId: number;
    createByUser: User;
    createdAt: string;
    updatedAt: string;
    

	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'businessId',
							'business',
							'attachmentId',
							'attachment',
							'expirationDate',
                            'createdByUserId',
                            'createByUser',
							'createdAt',
							'updatedAt'];
		super(object, properties);
	}
}
