import { DataType } from './dataType';

export class MinutesTemplate extends DataType {
  id: number;
  name: string;
  template: boolean;
  isActive: boolean;
  createdByUserId: boolean;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "name",
      "template",
      "isActive",
      "createdByUserId"
    ];
    super(object, properties);

  }
}
