import { DataType } from './dataType';
import { Receipt } from './receipt';
import { Item } from './item';
import { License } from './license';
import { ReceivableAccount } from './receivableAccount';

export class ReceiptItem extends DataType {
	id: number;
	receiptId: number;
	receipt: Receipt;
	itemId: number;
	item: Item;
	chargeItem: Item;
	cost: number;
	quantity: number;
	businessLicenseId: number;
	businessLicense: License;
	receivableAccountId: number
	receivableAccount: ReceivableAccount
	permitId: number;

	constructor(object: Object = {}){
		var properties = [
			'id',
			'receiptId',
			'receipt',
			'itemId',
			'item',
			'chargeItem',
			'cost',
			'quantity',
			'businessLicenseId',
			'businessLicense',
			'receivableAccountId',
			'receivableAccount',
			'permitId'];
		super(object, properties);

		if(object['receipt'] == undefined){
			this.receipt = new Receipt();
		}

		if(object['businessLicense'] == undefined){
			this.businessLicense = new License();
		}

		if(object['receivableAccount'] == undefined){
			this.receivableAccount = new ReceivableAccount();
		}
	}
}