import { DataType } from './dataType';
import { Address } from './address';
import { User } from './user';

export class MeetingType extends DataType {
  id: number;
  name: string;
  ownerId: number;
  owner: User;
  cutoffDaysBeforeMeeting: number;
  cutoffTime: string;
  cutoffEnforced: boolean;
  room: string;
  address: Address;
  numberingPattern: string;
  punctuation: string;
  numberSection: boolean;
  restartItemNumbering: boolean;
  isActive: boolean;
  createdByUserId: number;
  createdByUser: User;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "name",
      "ownerId",
      "owner",
      "cutoffDaysBeforeMeeting",
      "cutoffTime",
      "cutoffEnforced",
      "room",
      "address",
      "numberingPattern",
      "punctuation",
      "numberSection",
      "restartItemNumbering",
      "isActive",
      "createdByUserId",
      "createdByUser"
    ];
    super(object, properties);

  }
}
