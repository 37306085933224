import { DataType } from './dataType';
import { State } from "./state";
import { Address } from "./address";
import { TimeZone } from "./timeZone";

export class City extends DataType {
  id: number;
  name: string;
  address: Address;
  phone: string;
  fax: string;
  slug: string;
  description: string;
  timeZoneId: string;
  timeZone: TimeZone;
  targetGeneralLedger: string;
  logoUrl: string;
  signatureUrl: string;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "name",
      "address",
      "phone",
      "fax",
      "slug",
      "description",
      "timeZoneId",
      "timeZone",
      "targetGeneralLedger",
      "logoUrl",
      "signatureUrl"
    ];
    super(object, properties);

    if (!object["address"]) {
      this.address = new Address();
    }
  }
}
