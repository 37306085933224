import { Injectable } from '@angular/core';

import { 
	ResourceService,
	DataService
} from '../services/services';

@Injectable()
export class EventsResource extends ResourceService {

	constructor(public dataService: DataService) { 
		super(dataService, 'events');
	}

	init(params){
		this.url = 'events';

		if(params.urlModifiers){
			if(params.urlModifiers.opportunityId){
				this.url = 'opportunities/:opportunityId/events';
			}
		}
	}
}