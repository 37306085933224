import { DataType } from './dataType';
import { Role } from './role';
import { User } from './user';

export class Scope extends DataType {
	id: number;
	roleId: string;
	role: Role;
	userId: string;
	user: User;

	constructor(object: Object = {}){ 
		var properties = ['id','roleId','role','userId','user'];
		super(object, properties);
	}
}