import { Injectable } from '@angular/core';

import { 
	ResourceService,
	DataService
} from '../services/services';

@Injectable()
export class BusinessesResource extends ResourceService {

	constructor(public dataService: DataService) { 
		super(dataService, 'businesses');
	}

	init(params){

		this.url = 'businesses';
		
		if(params.urlModifiers){
			let modifiers = params.urlModifiers;

			if(modifiers.userId){
				this.url = 'users/:userId/businesses';
			}
		}
	}
}