import { DataType } from './dataType';
import { CashDrawer } from './cashDrawer';
import { PaymentSource } from './paymentSource';
import { User } from './user';

export class Batch extends DataType {
	id: number;
	number: number;
	cashDrawerId: number;
	cashDrawer: CashDrawer;
	paymentSourceId: string;
	paymentSource: PaymentSource;
	referenceDate: string;
	dateCreated: string;
	dateBalanced: string;
	cash: number;
	otherDeposits: number;
	status: string;
	deposited: boolean;
	depositId: number;
	enteredInGeneralLedger: boolean;
	createdByUserId: number;
	createdByUser: User;
	hasDraftReceipts: boolean;


	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'number',
							'cashDrawerId',
							'drawer',
							'paymentSourceId',
							'paymentSource',
							'referenceDate',
							'dateCreated',
							'dateBalanced',
							'cash',
							'otherDeposits',
							'status',
							'deposited',
							'depositId',
							'enteredInGeneralLedger',
							'createdByUserId',
							'createdByUser',
							'hasDraftReceipts'
						];
		super(object, properties);

		if(!object['createdByUser']){
			this.createdByUser = new User();
		}

		if(!object['paymentSource']){
			this.paymentSource = new PaymentSource();
		}

		if(!object['cashDrawer']){
			this.cashDrawer = new CashDrawer();
		}
	}
}