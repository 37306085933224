import { DataType } from "./dataType";
import { User } from "./user";
import { Fee } from "./fee";

export class LicensePenalty extends DataType {
  id: number;
  feeId: number;
  fee: Fee;
  name: string;
  status: string;
  updatedByUserId: number;
  udpatedByUser: User;
  createdAt: string;
  updatedAt: string;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "feeId",
      "fee",
      "name",
      "status",
      "updatedByUserId",
      "udpatedByUser",
      "createdAt",
      "updatedAt"
    ];
    super(object, properties);
  }
}
