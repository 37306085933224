import { DataType } from './dataType';
import { State } from './state';
import { Country } from './country';

export class Address extends DataType {
	address1: string;
	address2: string;
	city: string;
	stateId: string;
	state: State;
	stateName: string;
	zipCode: string;
	countryId: string;
	country: Country;

	constructor(object: Object = {}){ 
		var properties = [
							'address1',
							'address2',
							'city',
							'stateId',
							'state',
							'stateName',
							'zipCode',
							'countryId',
							'country'];
		super(object, properties);

		if(!object['state']){
			this.state = new State();
		}

		if(!object['country']){
			this.country = new Country();
		}
	}
}