import { DataType } from './dataType';

export class JobOutput extends DataType {
    id: number;
    jobId: number;
    name: string;
    location: string;
    deleted: boolean;
    deletedBy: number;
    createdAt: string;
    updatedAt: string;
    decription: string;

	constructor(object: Object = {}) {
		const properties = [
            'id',
            'jobId',
            'name',
            'location',
            'deleted',
            'deletedBy',
            'createdAt',
            'updatedAt',
            'decription'
        ];
		super(object, properties);
	}
}
