import { DataType } from './dataType';
import { User } from "./user";

export class Project extends DataType {
	id: number;
	name: string;
	description: string;
	fromDate: string;
	expireDate: string;
	status: string;
	financialsEntityId: number;
	updatedByUserId: number;
	updatedByUser: User;
  createdAt: string;
  updatedAt: string;

	constructor(object: Object = {}){ 
		var properties = [
							"id",
							'name',
							'description',
							'fromDate',
							'expireDate',
							'status',
							'financialsEntityId',
							'updatedByUserId',
						  'udpatedByUser',
  						'createdAt',
  						'updatedAt'];
		super(object, properties);
	}
}