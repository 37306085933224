import { Injectable } from '@angular/core';

import { 
	ResourceService,
	DataService
} from '../services/services';

@Injectable()
export class BusinessCertificationTypesResource extends ResourceService {

	constructor(public dataService: DataService) { 
		super(dataService, 'businesscertificationtypes');
	}

	init(params){

		this.url = 'businesscertificationtypes';
		
		if(params.urlModifiers){
			let modifiers = params.urlModifiers;

			if(modifiers.businessId){
				this.url = 'businesses/:businessId/certificationTypes';
			}
		}
	}
}