import { DataType } from "./dataType";
import { User } from "./user";
import { ReceivableAccount } from "./receivableAccount";

export class RecurringChargeCriteria extends DataType {
  id: number;
  accountId: number;
  account: ReceivableAccount;
  additionalInformation: string;
  startDate: string;
  endDate: string;
  totalAmountToBill: number;
  amountBilledToDate: number;
  occurrencesToBill: number;
  occurrencesBilledToDate: number;
  status: string;
  updatedByUserId: number;
  udpatedByUser: User;
  createdAt: string;
  updatedAt: string;
  billInJanuary: boolean;
  billInFebruary: boolean;
  billInMarch: boolean;
  billInApril: boolean;
  billInMay: boolean;
  billInJune: boolean;
  billInJuly: boolean;
  billInAugust: boolean;
  billInSeptember: boolean;
  billInOctober: boolean;
  billInNovember: boolean;
  billInDecember: boolean;
  lastBilledAt: string;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "accountId",
      "account",
      "additionalInformation",
      "startDate",
      "endDate",
      "totalAmountToBill",
      "amountBilledToDate",
      "occurrencesToBill",
      "occurrencesBilledToDate",
      "status",
      "updatedByUserId",
      "udpatedByUser",
      "createdAt",
      "updatedAt",
      "billInJanuary",
      "billInFebruary",
      "billInMarch",
      "billInApril",
      "billInMay",
      "billInJune",
      "billInJuly",
      "billInAugust",
      "billInSeptember",
      "billInOctober",
      "billInNovember",
      "billInDecember",
      "lastBilledAt",
    ];
    super(object, properties);
  }
}
