import { DataType } from './dataType';
import { User } from './user';
import { Batch } from './batch';

export class Receipt extends DataType {
	id: number;
	number: number;
	dateCreated: string;
	paymentTransactionId: number;
	transactionComment: string;
	showComment: boolean;
	status: string;
	voidComment: string;
	userId: number;
	user: User;
	payorId: number;
	payor: User;
	batchId: number;
	batch: Batch;
	total: number;
	tendered: number;
	change: number;

	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'number',
							'dateCreated',
							'paymentTransactionId',
							'transactionComment',
							'showComment',
							'status',
							'voidComment',
							'userId',
							'user',
							'payor_id',
							'payor',
							'batchId',
							'batch',
							'total',
							'tendered',
							'change'];
							
		super(object, properties);

		if(!object['status']){
			this.status = 'Draft';
		}

		if(!object['batch']){
			this.batch = new Batch();
		}

		if(!object['payor']){
			this.payor = new User();
		}
	}
}