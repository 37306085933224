import { DataType } from "./dataType";

export class LicenseType extends DataType {
  id: number;
  code: string;
  description: string;
  currentLicenseYear: number;
  renewalType: string;
  expirationDate: string;
  validFor: number;
  period: string;
  autoRenew: boolean;
  eligibleToCreateOnline: boolean;
  eligibleToRenewOnline: boolean;
  status: string;
  businessLicensePenaltyId: number;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "code",
      "description",
      "currentLicenseYear",
      "renewalType",
      "expirationDate",
      "validFor",
      "period",
      "autoRenew",
      "eligibleToCreateOnline",
      "eligibleToRenewOnline",
      "status",
      "businessLicensePenaltyId"
    ];
    super(object, properties);
  }
}
