import { DataType } from './dataType';
import { ReceivableAccount } from './receivableAccount';
import { ReceivableCharge } from './receivableCharge';

export class ReceivableBillingDetail extends DataType {
  accountId: number;
  account: ReceivableAccount;
  billDate: string;
  activityName: string;
  presentationSequence: string;
  receivableChargeId: number;
  receivableCharge: ReceivableCharge;
  description: string;
  additionlInformation: string;
  amount: number;
  jobId: number;

  constructor(object: Object = {}) {
    let properties = [
      'accountId',
      'account',
      'billDate',
      'activityName',
      'presentationSequence',
      'receivableChargeId',
      'receivableCharge',
      'description',
      'additionlInformation',
      'amount',
      'jobId'
    ];
    super(object, properties);
  }
}
