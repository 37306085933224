import { DataType } from './dataType';

export class AttendeeRole extends DataType {
  id: number;
  name: string;
  notifyWhenPublished: boolean;
  notifyWhenPacketGenerated: boolean;
  notifyPostMeeting: boolean;
  canVote: boolean;
  isActive: boolean;
  createdByUserId: number;

  constructor(object: Object = {}) {
    var properties = [
      'id',
      'name',
      'notifyWhenPublished',
      'notifyWhenPacketGenerated',
      'notifyPostMeeting',
      'canVote',
      'isActive',
      'createdByUserId'
    ];
    super(object, properties);

  }
}
