import { DataType } from './dataType';

export class PetLicense extends DataType {
	id: number;
	name: string;

	constructor(object: Object = {}){ 
		var properties = [
            'id',
            'petId',
            'number',
            'expirationDate',
            'issuedBy',
            'createdAt',
            'updatedAt',
            'updatedByUserId',
        ];
		super(object, properties);
	}
}
