import { Injectable } from '@angular/core';

import { 
	ResourceService, 
	DataService 
} from '../services/services';

@Injectable()
export class MerchantSettingsResource extends ResourceService {
  constructor(public dataService: DataService) {
    super(dataService, 'settings/payments/merchant');
  }
}
