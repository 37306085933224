import { DataType } from './dataType';
import { User } from './user';

export class GeneralLedgerEntry extends DataType {
	id: number;
	dateCreated: string;
	userId: number;
	user: User;
	processedById: number;
	processedBy: User;
	dateProcessed: string;
	debit: number;
	credit: number;


	constructor(object: Object = {}){ 
		var properties = ['id','dateCreated','userId','user','processedById','processedBy','dateProcessed','debit','credit'];
		super(object, properties);
	}
}