import { DataType } from './dataType';
import { LicenseType } from './licenseType';
import { DocumentType } from './documentType';
import { User } from './user';

export class LicenseTypeDocumentType extends DataType {
	id: number;
    licenseTypeId: number;
    licenseType: LicenseType;
    documentTypeId: number;
    documentType: DocumentType;
    createdByUserId: number;
    createdByUser: User;

	constructor(object: Object = {}){ 
		var properties = [
							"id",
                            'licenseTypeId',
                            'licenseType',
                            'documentTypeId',
                            'documentType',
                            'createdByUserId',
                            'createdByUser'];
		super(object, properties);
	}
}