import { DataType } from './dataType';
import { MinutesTemplate } from './minutesTemplate';

export class MeetingItemType extends DataType {
  id: number;
  name: string;
  level: string;
  trackVotes: boolean;
  minutesTemplateId: number;
  minutesTemplate: MinutesTemplate;
  justification: string;
  isActive: boolean;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "name",
      "level",
      "trackVotes",
      "minutesTemplateId",
      "minutesTemplate",
      "justification",
      "isActive"
    ];
    super(object, properties);

  }
}
