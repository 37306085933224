import { DataType } from './dataType';

export class RolePermission extends DataType {
	id: number;
	roleId: number;
	permissionId: number;

	constructor(object: Object = {}){ 
		var properties = ['id','roleId','permissionId'];
		super(object, properties);
	}
}