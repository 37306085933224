import { DataType } from "./dataType";

export class Rpc extends DataType {
  method: string;
  params: string;

  constructor(object: Object = {}) {
    var properties = [
      "method",
      "params",
    ];
    super(object, properties);
  }
}
