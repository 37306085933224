import { Injectable } from "@angular/core";

import { ResourceService, DataService } from "../services/services";

@Injectable()
export class ChargeTypeAmountsResource extends ResourceService {
  constructor(public dataService: DataService) {
    super(dataService, "chargetypeamountslist");
  }

  init(params) {
    this.url = "chargetypeamountslist";

    if (params.urlModifiers) {
      let modifiers = params.urlModifiers;

      if (modifiers.chargeTypeId) {
        this.url = "chargeTypes/:chargeTypeId/amounts";
      }
    }
  }
}
