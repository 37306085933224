import { DataType } from './dataType';

export class Bank extends DataType {
	id: number;
	name: string;
	description: string;
	phoneNumber: string;
	routingNumber: string;
	status: string;

	constructor(object: Object = {}){ 
		var properties = [
							"id",
							'name',
							'description',
							'phoneNumber',
							'routingNumber',
							'status'];
		super(object, properties);
	}
}