import { Injectable } from '@angular/core';
import { DataService } from './data.service';

import { DataType } from '../dataTypes/dataType';
import { ResourceParams } from '../dataTypes/resourceParams';

export class ResourceGetResponsePage extends DataType {
	page: number;
	totalCount: number;

	constructor(object: any = {}){ 
		super(object, ['page', 'totalCount', 'totals']);
	}
}

export class ResourceGetResponse extends DataType {
	items: Array<any>;
	page: ResourceGetResponsePage = new ResourceGetResponsePage();

	constructor(object: any = {}){ 
		super(object, ['items', 'page']);

		if(object['page']){
			this.page = new ResourceGetResponsePage(object['page']);
		}
	}

	private _boldResults(filters){

		this.items.forEach( (item) => {

			filters.forEach( (filter) => {

				filter.forEach( (filterItem) => {
					if( eval('item.'+filterItem.property) ){

						var string = this._getBoldedString( eval('item.'+filterItem.property), filterItem.value );

						eval( 'item.'+ filterItem.property + " = '" + string + "'" );
					}
				})
			})
	    })
	}

	private _getBoldedString(string, substring){
		var searchStrLen = substring.length;
	    if (searchStrLen == 0) {
	        return [];
	    }
	    var startIndex = 0, index, indices = [];

        var str = string.toLowerCase();
        var searchStr = substring.toLowerCase();

	    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
	        indices.push(index);
	        startIndex = index + searchStrLen;
	    }

	    var subStrings = [];
	    
	    for(var i = 0; i < indices.length; i++){
	    	subStrings.push(string.substring(indices[i], indices[i] + substring.length) );
	    }

	    if(indices.length > 0){
	    	for(var i = indices.length - 1; i >= 0; i--){

	    		if(indices[i] != 0){
	    			string = string.slice(0, indices[i]) + "<b>" + string.slice(indices[i], indices[i] + substring.length) + "</b>" + string.slice(indices[i] + substring.length, string.length);
	    		}else{
	    			string = "<b>" + string.slice(indices[i], indices[i] + substring.length) + "</b>" + string.slice(indices[i] + substring.length, string.length);
	    		}
		    }
	    }

		return string;
	}
}


export class ResourceService {

	dataService: DataService;
	url: string;
	cacheData: boolean = false;

	constructor(
		dataService: DataService,
		url: string = ''
	){
		this.dataService = dataService;
		this.url = url;
	}

	init(value){}

	postInit(value){}
	post(value){
		
		this.init(value);
		this.postInit(value);

		return this.dataService.post(this.url, value).then( response => {
			return Promise.resolve(response);
		}, (e) => {
			return Promise.reject(e);
		})
	}

	create(value){
		return this.post(value)
	}

	getInit(params){};
	get(params = new ResourceParams, cacheData?){
		
		this.init(params);
		this.getInit(params);

		if(cacheData === undefined){
			cacheData = this.cacheData;
		}
		
		return this.dataService.get(this.url, params, cacheData).then( response => {

			if(response && response.items != undefined){
				response = new ResourceGetResponse(response);
			}			

			if(params.$bold == true){

				response._boldResults(params.filters);
			}
			return Promise.resolve(response);
		}, (e) => {
			return Promise.reject(e);
		})
	}

	select(id, modifiers = {}){

		this.init({ urlModifiers: modifiers});
		
		var params = new ResourceParams(
			{ 
				urlModifiers: modifiers,
				filters: [
					[
						{
							property: 'id',
							operator: 'eq',
							value: id
						}
					]
				] 
			}
		);

		return this.dataService.get(this.url, params).then( response => {

			response = new ResourceGetResponse(response);

			if(response.items.length > 0){
				return Promise.resolve(response.items[0]);
			}

			return Promise.resolve(response);
		}, (e) => {
			return Promise.reject(e);
		})
	}

	putInit(value){};
	put(value = {}){
		
		this.init(value);
		this.putInit(value);

		return this.dataService.update(this.url, value).then( response => {
			return Promise.resolve(response);
		}, (e) => {
			return Promise.reject(e);
		})
	}

	update(value = {}){
		return this.put(value);
	}

	deleteInit(value){}
	delete(value = {}){

		this.init(value);
		this.deleteInit(value);

		return this.dataService.delete(this.url, value).then( response => {
			return Promise.resolve(response);
		}, (e) => {
			return Promise.reject(e);
		})
	}
}