import { Injectable } from '@angular/core';

import { 
	ResourceService,
	DataService
} from '../services/services';

@Injectable()
export class MeetingTypeOutlinesResource extends ResourceService {

	constructor(public dataService: DataService) { 
		super(dataService, 'meetingtypes/:meetingTypeId/outlines');
	}
}