import { DataType } from './dataType';

export class CashDrawer extends DataType {
	id: number;
	name: string;
	balance: number;
	status: string;

	constructor(object: Object = {}){ 
		var properties = ['id','name','status','balance'];
		super(object, properties);
	}
}