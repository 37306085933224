import { DataType } from './dataType';

export class State extends DataType {
	id: number;
	name: string;

	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'name',
							'abbreviation',
							'countryId'];
		super(object, properties);
	}
}