import { DataType } from './dataType';
import { Address } from './address';
import { ZoningCode } from './zoningCode';

export class Location extends DataType {
	id: number;
	owner: string;
	address: Address;
	inCityLimits: boolean;
	zoningCodeId: number;
	zoningCode: ZoningCode;
	type: string; //enum: 'single family', multiple family, comercial, industrial
	numberOfEvents: number;

	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'owner',
							'address',
							'inCityLimits',
							'zoningCodeId',
							'zoningCode',
							'numberOfEvents'];
		super(object, properties);

		if(object['address'] == undefined){
			this.address = new Address();
		}

		if(object['zoningCode'] == undefined){
			this.zoningCode = new ZoningCode();
		}
	}
}