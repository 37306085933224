import { DataType } from './dataType';

export class OpportunityType extends DataType {
	id: number;
	name: string;
	description: string;
	isActive: boolean;
	createdAt: string;
	updatedAt: string;
	updatedByUserId: number;

	constructor(object: Object = {}){ 
		var properties = [
			'id',
			'name',
			'description',
			'isActive',
			'createdAt',
			'updatedAt',
			'updatedByUserId'
		];
		super(object, properties);
	}
}