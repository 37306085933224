import { Injectable } from '@angular/core';

import { 
	ResourceService,
	DataService
} from '../services/services';

@Injectable()
export class EventTypeSettingsResource extends ResourceService {

	constructor(public dataService: DataService) { 
		super(dataService, 'eventtypes/settings');
	}

	init(params){

		this.url = 'eventtypes/settings';
		
		if(params.urlModifiers){
			let modifiers = params.urlModifiers;

			if(modifiers.eventTypeId){
				this.url = 'eventtypes/:eventTypeId/settings';
			}
		}
	}
}