import { Injectable } from '@angular/core';

import { 
	ResourceService,
	DataService
} from '../services/services';

@Injectable()
export class ContactCertificationTypesResource extends ResourceService {

	constructor(public dataService: DataService) { 
		super(dataService, 'contactcertificationtypes');
	}

	init(params){

		this.url = 'contactcertificationtypes';
		
		if(params.urlModifiers){
			let modifiers = params.urlModifiers;

			if(modifiers.contactId){
				this.url = 'contacts/:contactId/certificationTypes';
			}
		}
	}
}