import { DataType } from './dataType';
import { User } from "./user";

export class AccountGroup extends DataType {
	id: number;
	group: string;
	description: string;
	validForAccountType: string;
	routingNumber: string;
	status: string;
  updatedByUserId: number;
  udpatedByUser: User;
  createdAt: string;
  updatedAt: string;

	constructor(object: Object = {}){ 
		var properties = [
							"id",
							'name',
							'description',
							'fromDate',
							'expireDate',
							'financialsEntityId',
              'updatedByUserId',
              'updatedByUser',
              'createdAt',
              'updatedAt'];
		super(object, properties);
	}
}