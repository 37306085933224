import { DataType } from './dataType';

export class LicenseCertificateSettings extends DataType {
	licenseNumberLabel: string;
	certificateTitle: string;
	licenseYearLabel: string;
	logoUrl: string;
	signatureUrl: string;

	constructor(object: Object = {}){ 
		var properties = [
							'licenseNumberLabel',
							'certificateTitle',
							'licenseYearLabel',
							'logoUrl',
							'signatureUrl'];
		super(object, properties);

	}
}
