import { DataType } from './dataType';
import { LicenseType, } from './licenseType';
import { User } from './user';

export class LicenseTypeRenewalHistory extends DataType {
	id: number;
	typeId: number;
	type: LicenseType;
	licenseYear: string;
	lettersPrintedAt: string;
	renewalsCreatedAt: string;
	createdByUserId: number
	createdByUser: User

	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'typeId',
					    	'licenseYear',
					    	'lettersPrintedAt',
					    	'renewalsCreatedAt',
					    	'createdByUserId'];
		super(object, properties);
	}
}