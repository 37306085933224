import { DataType } from './dataType';
import { Fee } from './fee';

export class LicenseTypeFee extends DataType {
	id: number;
	typeId: number;
	feeId: number;
	fee: Fee;
	required: boolean;
	defaultQuantity: number;
	trackAsAttribute: boolean;

	constructor(object: Object = {}){ 
		var properties = [
							"id",
							'typeId',
					    	'feeId',
					    	'fee',
					    	'required',
					    	'defaultQuantity',
					    	'trackAsAttribute'];
		super(object, properties);
	}
}