import { DataType } from './dataType';
import { LicenseType } from './licenseType';
import { Business } from './business';

export class License extends DataType {
	id: number;
	number: string;
	typeId: string;
	type: LicenseType;
	year: string;
	dateCreated: string;
	expirationDate: string;
	status: string;
	businessId: number;
	business: Business;
	exemptFromFee: number;
	exemptReason: string;
	dateIssued: string;
	stipulations: string;
	feeTotal: string;
	licenseTypeRenewalBatchId: number;
	dateCanceled: string;
	cancelationReason: string;

	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'number',
							'typeId',
							'type',
							'year',
							'dateCreated',
							'expirationDate',
							'status',
							'businessId',
							'business',
							'exemptFromFee',
							'exemptReason',
							'dateIssued',
							'stipulations',
							'feeTotal',
							'licenseTypeRenewalBatchId',
							'dateCanceled',
							'cancelationReason'];
		super(object, properties);

		if(!object['type']){
			this.type = new LicenseType();
		}

		if(!object['business']){
			this.business = new Business();
		}
	}
}