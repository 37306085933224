import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { City } from '../dataTypes/city';

import { AppSettings } from '../settings';
const CACHE = {};
@Injectable()
export class ApiService {

	private requestQueue: Array<any> = [];

	public ApiUrl: string = '/';
	public ServerWithApiUrl: string;

	private actionUrl: string = undefined;

	static city: string = undefined;
	static token: string = undefined;
	static entity: string = undefined;

	private processingRequest: boolean = false;

	constructor(
		private http: HttpClient,
		private appSettings: AppSettings
	){
		this.actionUrl = this.ServerWithApiUrl + 'users';

		this.ServerWithApiUrl = this.appSettings.apiServer+ this.appSettings.apiUrl;
	}

	stringToHash(string){
		let hash = 0;
		if(string.length === 0) return hash;

		for(let i = 0; i < string.length; i++){
			let char = string.charCodeAt(i);
			hash = ((hash << 5) - hash) + char;
			hash = hash & hash;
		}

		return hash;
	}

	moveToNextRequest(){
		this.requestQueue.shift();

		this.loopRequests();
	}

	loopRequests(){

		if(this.requestQueue.length > 0){

			this.processingRequest = true;

			switch(this.requestQueue[0].type){
				case 'get':
						this.executeGet(this.requestQueue[0]);
					break;
				case 'put':
						this.executePut(this.requestQueue[0]);
					break;
				// case 'patch':
				// 		this.executePut(this.requestQueue[0]);
				// 	break;
				case 'post':
						this.executePost(this.requestQueue[0]);
					break;
				case 'delete':
						this.executeDelete(this.requestQueue[0]);
					break;
				default:
					this.executeGet(this.requestQueue[0]);
			}
		}else{
			this.processingRequest = false;
		}
	}

	get(data, cacheData = false): Promise<any> {

		var promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'get',
				resolve: resolve,
				reject: reject,
				data: data,
				cacheData: cacheData,
			})
		})

		if(this.processingRequest == false){

			this.loopRequests();
		}

		return promise;

	}

	executeGet(request){
		if(request.cacheData && CACHE[request.data]){
			request.resolve(CACHE[request.data]);
			this.moveToNextRequest();
		}else{
			this.getHeader().then( options => {
	
				this.http
						.get(this.ServerWithApiUrl+request.data, options)
						.toPromise()
						.then(this.extractData)
						  .catch( (e) => {
	
							  request.reject(e);
	
							  this.moveToNextRequest();
						  })
						  .then( data => {
								if(request.cacheData) CACHE[request.data] = data;
								request.resolve(data);

								this.moveToNextRequest();
						  })
			})
		}
	}

	post(url, data): Promise<any>{

		var promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'post',
				resolve: resolve,
				reject: reject,
				data: data,
				url: url
			})
		})

		if(this.processingRequest == false){
			this.loopRequests();
		}

		return promise;

	}

	executePost(request){

		this.getHeader().then( options => {
			this.http
				.post(this.ServerWithApiUrl + request.url, request.data, options)
				.toPromise()
				.then( (res: any) => {

					request.resolve(this.extractData(res));

					this.moveToNextRequest();
				})
	      		.catch( (e) => {

	      			request.reject(e);

	      			this.moveToNextRequest();
	      		});
		});
	}

	put(url, data): Promise<any>{

		var promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'put',
				resolve: resolve,
				reject: reject,
				data: data,
				url: url
			})
		})

		if(this.processingRequest == false){
			this.loopRequests();
		}

		return promise;

	}

	executePut(request){

		this.getHeader().then( options => {
			this.http
				.put(this.ServerWithApiUrl + request.url, request.data, options)
				.toPromise()
				.then( (res: any) => {
					request.resolve(this.extractData(res));

					this.moveToNextRequest();
				})
	      		.catch( (e) => {

	      			request.reject(e);

	      			this.moveToNextRequest();
	      		});
		});
	}

	delete(url): Promise<any>{

		var promise = new Promise( (resolve, reject) => {
			this.requestQueue.push({
				type: 'delete',
				resolve: resolve,
				reject: reject,
				url: url
			})
		})

		if(this.processingRequest == false){
			this.loopRequests();
		}

		return promise;

	}

	executeDelete(request){

		this.getHeader().then( options => {

    		this.http
				.delete(this.ServerWithApiUrl + request.url, options)
				.toPromise()
				.then( (res: any) => {
					request.resolve(this.extractData(res));

					this.moveToNextRequest();
				})
	      		.catch( (e) => {

	      			request.reject(e);

	      			this.moveToNextRequest();
	      		});
    	})
	}

	create(url, data): Promise<any>{

		return this.post(url, data);
	}

	update(url, data): Promise<any>{

		return this.put(url, data);
	}

	private extractData(res: any){

		let body = res;

		return body;
	}

	private handleError(error: any){

		return error;
	}

	 getHeader(){
		var promise = new Promise( (resolve, reject) => {
			var headers = new HttpHeaders();

			if(ApiService.token){
				headers = headers.set('X-Token', ApiService.token);
			}

			if(ApiService.entity){
				headers = headers.set('X-Entity', ApiService.entity);
			}

			if(ApiService.city && ApiService.city != 'admin'){
	    		headers = headers.set('X-City', ApiService.city);
	    	}

			var options = {
	    		headers: headers
	    	};

	    	resolve(options);

		})

    	return promise
	}

	static setToken(token){
		ApiService.token = token;
	}

	static setEntity(entity){
		ApiService.entity = entity;
	}

	static setCity(city){
		ApiService.city = city;
	}
}