import { Injectable } from '@angular/core';

import { 
	ResourceService,
	DataService
} from '../services/services';

@Injectable()
export class PetLicensesResource extends ResourceService {

	constructor(public dataService: DataService) { 
		super(dataService, 'petlicenseslist');
	}

	init(params){
		this.url = 'petlicenseslist';

		if(params.urlModifiers){
			if(params.urlModifiers.petId){
				this.url = 'pets/:petId/petLicenses';
			}
		}
	}
}