import { DataType } from './dataType';
import { ReceivableAccountType } from './receivableAccountType';
import { User } from './user';

export class ReceivableBillingHistory extends DataType {
  id: number;
  accoutTypeId: Number;
  accountType: ReceivableAccountType;
  billDate: String;
  dueDate: String;
  status: String;
  updatedByUserId: Number;
  updatedByUser: User;
  createdAt: String;
  updatedAt: String;
  jobId: number;

  constructor(object: Object = {}) {
    let properties = [
      'id',
      'accoutTypeId',
      'accoutType',
      'billDate',
      'dueDate',
      'status',
      'updatedByUserId',
      'udpatedByUser',
      'createdAt',
      'updatedAt',
      'jobId'
    ];
    super(object, properties);
  }
}
