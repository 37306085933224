
import { Optional, Injectable, Inject } from '@angular/core';
//@dynamic
@Injectable()
export class AppSettings {

  public static thorHostName: string;
  public apiUrl = '/'
  public apiServer: string;

  constructor(
    @Inject('config') config: any,
  ){
    if(config && config.thorHostName){
      AppSettings.thorHostName = config.thorHostName
      this.apiServer = config.thorHostName
    }else{
      this.apiServer = 'http://localhost:3000';
    }
  }
  
}
