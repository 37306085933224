import { DataType } from './dataType';
import { Application } from './application';

export class Item extends DataType {
	id: number;
	name: string;
	description: string;
	cost: number;
	quantity: number;
	canChangeCost: boolean;
	canChangeQuantity: boolean;
	applicationId: number;
	application: Application;
	creditAccount: string;
	debitAccount: string;
	status: string;

	constructor(object: Object = {}){ 
		var properties = [
			'id',
			'name',
			'description',
			'cost',
			'quantity',
			'canChangeCost',
			'canChangeQuantity',
			'applicationId',
			'Application',
			'creditAccount',
			'debitAccount',
			'status'];

		var map = {
			id: 'id',
			name: 'name',
			description: 'description',
			cost: 'unitCost',
			quantity: 'quantity',
			requiresChargeAssociation: 'requiresChargeAssociation',
			canChangeQuantity: 'canChangeQuantity',
			applicationId: 'applicationId',
			application: 'application',
			creditAccount: 'creditAccount',
			debitAccount: 'debitAccount',
			status: 'status'
		}

		super(object, properties, map);
	}
}