import { Injectable } from '@angular/core';

import { 
	ResourceService,
	DataService
} from '../services/services';

@Injectable()
export class StatesResource extends ResourceService {

	cacheData = true;
	
	constructor(public dataService: DataService) { 
		super(dataService, 'states');
	}
}