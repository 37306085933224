import { DataType } from './dataType';

export class Penalty extends DataType {
	id: number;
	amount: number;
	percent: number;
	gl: string;
	status: string;

	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'amount',
							'percent',
							'gl',
							'status'];
		super(object, properties);
	}
}