import { DataType } from "./dataType";
import { User } from "./user";
export class ReceivableAccountType extends DataType {
  id: number;
  name: string;
  description: string;
  status: string;
  updatedByUserId: number;
  updatedByUser: User;
  createdAt: string;
  updatedAt: string;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "name",
      "description",
      "status",
      "updatedByUserId",
      "updatedByUser",
      "createdAt",
      "updatedAt",
    ];
    super(object, properties);
  }
}
