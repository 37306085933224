import { DataType } from './dataType';
import { Bank } from './bank';

export class BankAccount extends DataType {
	id: number;
	bankId: number;
	bank: Bank;
	name: string;
	description: string;
	type: string;
	number: string;
	status: string;

	constructor(object: Object = {}){ 
		var properties = [
							"id",
							'bankId',
							'bank',
							'name',
							'description',
							'type',
							'number',
							'status'];
		super(object, properties);

		if(!object['bank']){
			this.bank = new Bank();
		}
	}
}