import { DataType } from './dataType';

export class TimeZone extends DataType {
	id: number;
	code: string;

	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'code'];
		super(object, properties);
	}
}