import { DataType } from './dataType';

export class Role extends DataType {
	id: number;
	name: string;
	permissionsList: Array<any>;

	constructor(object: Object = {}){ 
		var properties = ['id','name','permissionsList'];
		super(object, properties);
	}
}