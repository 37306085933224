import { DataType } from './dataType';

export class Country extends DataType {
	id: number;
	name: string;

	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'name',
							'abbreviation'];
		super(object, properties);
	}
}