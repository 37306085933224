import { DataType } from "./dataType";
import { User } from "./user";
import { LicensePenalty } from "./licensePenalty";


export class LicensePenaltyCriteria extends DataType {
  id: number;
  businessLicensePenaltyId: number;
  businessLicensePenalty: LicensePenalty;
  fromAge: number;
  toAge: number;
  amount: number;
  percent: number;
  amountToUse: string;
  updatedByUserId: number;
  udpatedByUser: User;
  createdAt: string;
  updatedAt: string;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "businessLicensePenaltyId",
      "businessLicensePenalty",
      "fromAge",
      "toAge",
      "amount",
      "percent",
      "amountToUse",
      "updatedByUserId",
      "udpatedByUser",
      "createdAt",
      "updatedAt"
    ];
    super(object, properties);
  }
}
