import { DataType } from "./dataType";
import { User } from "./user";
import { ReceivableAccountType } from "./receivableAccountType";
export class ReceivableBillingPrompt extends DataType {
  id: number;
  accountTypeId: number;
  accountType: ReceivableAccountType;
  lastBilled: string;
  billDate: string;
  dueDate: string;
  status: string;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "accountTypeId",
      "accountType",
      "lastBilled",
      "billDate",
      "dueDate",
      "status",
    ];
    super(object, properties);
  }
}
