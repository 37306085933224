import { DataType } from './dataType';

export class AccessToken extends DataType {
	token: string;
	expires: string;

	constructor(object: Object = {}){ 
		var properties = ['token', 'expires'];
		super(object, properties);
	}
}