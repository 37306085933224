import { DataType } from "./dataType";
import { Customer } from "./customer";
import { Attachment } from "./attachment";
import { User } from "./user";

export class CustomerAttachment extends DataType {
  id: number;
  customerId: number;
  customer: Customer;
  attachmentId: number;
  attachment: Attachment;
  expirationDate: string;
  createdByUserId: number;
  createByUser: User;
  createdAt: string;
  updatedAt: string;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "customerId",
      "customer",
      "attachmentId",
      "attachment",
      "expirationDate",
      "createdByUserId",
      "createByUser",
      "createdAt",
      "updatedAt",
    ];
    super(object, properties);
  }
}
