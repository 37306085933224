import { DataType } from './dataType';

export class LicenseRenewalSettings extends DataType {
	reuseLicenseNumber: boolean;
	renewalRange: number;
	createRange: number;
	showReturnAddress: boolean;
	showInstructions: boolean;
	showSignature: boolean;
	introduction: string;
	options: string;
	disclaimer: string;

	constructor(object: Object = {}){ 
		var properties = [
							'reuseLicenseNumber',
							'renewalRange',
							'createRange',
							'showReturnAddress',
							'showInstructions',
							'showSignature',
							'introduction',
							'options',
							'disclaimer'];
		super(object, properties);

	}
}