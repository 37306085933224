import { Injectable } from '@angular/core';

import { ResourceParams } from '../dataTypes/resourceParams';

import { ApiService } from './api.service';

@Injectable()
export class DataService {

	constructor(
		private apiService: ApiService
	){ }

	post(url, value): Promise<any> {

		url = this.getUrl(url, value);

		if(value.urlModifiers && value.urlModifiers['id']){
			url += '/'+value.urlModifiers['id'];
		}

		delete value.urlModifiers;

		return this.apiService.post(url.toLowerCase(), value).then( results => {
	    	return Promise.resolve(results);
	    }, (e) => {
	    	return Promise.reject(e)
	    });
		    
	}

	create(data, value): Promise<any> {
		return this.post(data, value);
	}

	get(url, params:ResourceParams = new ResourceParams, cacheData = false ): Promise<any> {
		
		var queryParams = this.buildQueryString(params);

		url = this.getUrl(url, params);
		
		return this.apiService.get(url.toLowerCase()+queryParams, cacheData).then( results => {

			if(params['$all']){
				params.limit = results.page.totalCount;

				var queryParams = this.buildQueryString(params);
				
				return this.apiService.get(url.toLowerCase()+queryParams, cacheData).then( results => {
					return Promise.resolve(results);
				}, (e) => {
			    	return Promise.reject(e)
			    });
			}else{
				return Promise.resolve(results);
			}
	    	
	    });    
	}

	buildQueryString(params){

		var queryParams = '?offset='+params.offset+'&limit='+params.limit;

		if(params.sort){
			if(params.sort.length > 0){
				var sortString = '';

				params.sort.forEach( value=> {
					if(sortString.length > 0){
						sortString += ",";
					}

					sortString += value.field+':'+value.order;

				})

				queryParams += '&sortby='+sortString;
			}				
		}

		if(params.filters){
			var filterString = '';

			params.filters.forEach(filter => {

				var fieldString = '';

				filter.forEach( field => {

					if(fieldString.length > 0){
						fieldString += ',';
					}

					fieldString += field.property +':'+field.operator+':' + field.value;
				})

				if(fieldString.length > 0){
					fieldString =  "(" + fieldString + ")"
				}

				if(filterString.length > 0){
					filterString += ',';
				}

				filterString += fieldString;
			})

			queryParams += "&filter="+encodeURIComponent(filterString);
		}

		if(params.expand){
			queryParams += "&expand="+params.expand;
		}

		if(params.total){
			queryParams += "&total="+params.total;
		}

		return queryParams;
	}

	put(url, value): Promise<any> {
				
		var params = new ResourceParams({})

		if(value.urlModifiers){
			
			params['urlModifiers'] = value.urlModifiers;

			if(value.urlModifiers.id){
				params['filters'] = [
					[
						{
							property: 'id',
							operator: 'eq',
							value: value.urlModifiers.id
						}
					]
				]
			}
		}


		return this.get( url, params ).then ( results => {

			if(results.items != undefined){
				var item = results.items[0];
			}else{
				var item = results;
			}				

			for(var prop in item){

				item[prop] = prop in value ? value[prop] : item[prop];
			}

			for(var prop in value){
				if(prop != 'urlModifiers'){
					item[prop] = value[prop];
				}					
			}

			url = this.getUrl(url, value);

			if(value.urlModifiers){
				if(value.urlModifiers.id){
					url += '/' + value.urlModifiers.id
				}
			}

			return this.apiService.update( url.toLowerCase(), item).then( results => {
		    	return Promise.resolve(results);
		    }, (e) => {
		    	return Promise.reject(e)
		    });

		}, (e) => {
	    	return Promise.reject(e)
	    });

	}

	update(data, value): Promise<any> {
		return this.put(data, value);
	}

	public delete(url, value){
		
		url = this.getUrl(url, value);

		if(value.urlModifiers && value.urlModifiers['id']){
			url += '/'+value.urlModifiers['id'];
		}else if(value['id']){
			url += '/'+value['id'];
		}

		return this.apiService.delete(url.toLowerCase()).then( results => {
	    	return Promise.resolve(results);
	    }, (e) => {
	    	return Promise.reject(e)
	    });
	}

	getUrl(url, params): string {

		for(var param in params.urlModifiers){

			url = url.replace(':'+param, params.urlModifiers[param]);
			
		}

		return url;
	}
}