import { DataType } from './dataType';

export class Event extends DataType {
	id: number;
	eventTypeId: string;
	description: number;
	data: string;
	createdAt: string;
	createdByUserId: number;

	constructor(object: Object = {}){ 
		var properties = [
			'id',
			'eventTypeId',
			'description',
			'data',
			'createdAt',
			'createdByUserId'];
			
		super(object, properties);
	}
}