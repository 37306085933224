import { Injectable, EventEmitter } from '@angular/core';
import {
  Router,
  NavigationStart,
  ActivatedRoute,
  Params
} from '@angular/router';
import { Response, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppSettings } from '../settings';

import { ResourceParams } from '../dataTypes/resourceParams';
import { City } from '../dataTypes/city';

@Injectable()
export class CurrentTownService {
  public ApiUrl: string = '/';
  public ServerWithApiUrl: string;

  currentTownChange = new EventEmitter();
  town: City;
  slug: string;

  constructor(
    private http: HttpClient, private route: ActivatedRoute,
    private appSettings: AppSettings,
  ) {
    this.ServerWithApiUrl = this.appSettings.apiServer + this.appSettings.apiUrl;
  }

  setActiveRoute(route) {
    this.route = route;

    this.route.params.forEach((params: Params) => {
      if (this.slug != params['town']) {
        this.slug = params['town'];

        this.get().then(town => {
          this.emitChangeEvent(town);
        });
      }
    });

    if (this.slug != this.getTown()) {
      this.slug = this.getTown();

      this.get().then(town => {
        this.emitChangeEvent(town);
      });
    }
  }

  getTown() {
    return this.route.snapshot.params['town'];
  }

  emitChangeEvent(town) {
    this.currentTownChange.emit(town);
  }

  getChangeEmitter() {
    return this.currentTownChange;
  }

  get(): Promise<any> {
    var promise = new Promise((resolve, reject) => {
      var accessToken = JSON.parse(sessionStorage.getItem('accessToken'));

      if (accessToken == undefined) {
        resolve(undefined);
      } else if (typeof this.town !== 'undefined') {
        resolve(this.town);
      } else {
        if (!this.slug) {
          resolve(undefined);
        } else {
          var headers = new HttpHeaders();

          if (accessToken) {
            headers = headers.set('X-Token', accessToken.token);
          }
          var options = {
            headers: headers
          };

          return this.http
            .get(
              this.appSettings.apiServer +
                '/cities?expand=address.state&filter=(slug:eq:' +
                this.slug +
                ')',
              options
            )
            .toPromise()
            .then((res: any) => {
              var data = res;

              if (data.page.totalCount > 0) {
                this.town = data.items[0];
              } else {
                this.town = undefined;
              }

              resolve(this.town);
            });
        }
      }
    });

    return promise;
  }

}
