import { DataType } from './dataType';

export class EventType extends DataType {
	id: number;
	name: string;
	applicationId: number;
	recordEvent: boolean;
	archiveAfter: string;
	updatedAt: string;
	updatedByUserId: number;

	constructor(object: Object = {}){ 
		var properties = [
			'id',
			'name',
			'applicationId',
			'recordEvent',
			'archiveAfter',
			'updatedAt',
			'updatedByUserId'];
			
		super(object, properties);
	}
}