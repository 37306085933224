import { DataType } from './dataType';

export class OpportunityTypeDocumentType extends DataType {
	id: number;
	name: string;
	isActive: boolean;
	createdAt: string;
	updatedAt: string;
	updatedByUserId: number;

	constructor(object: Object = {}){ 
		var properties = [
			'id',
            'opportunityTypeId',
            'opportunityType',
            'documentTypeId',
            'documentType',
			'loginRequired',
			'updatedByUserId'
		];
		super(object, properties);
	}
}