import { DataType } from './dataType';

export class Application extends DataType {
	id: number;
	name: string;
	useInFees: boolean;
	useInChargeItems: boolean;

	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'name'];
		super(object, properties);
	}
}