import { DataType } from './dataType';
import { Address } from './address';

export class PermitParty extends DataType {
	id: number;
	title: string;
	name: string;
	address: Address;
	homePhone: string;
	cellPhone: string;
	workPhone: string;
	fax: string;
	email: string;

	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'title',
							'name',
							'address',
							'homePhone',
							'cellPhone',
							'workPhone',
							'fax',
							'email'];
		super(object, properties);

		if(object['address'] == undefined){
			this.address = new Address();
		}
	}
}