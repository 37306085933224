import { DataType } from "./dataType";
import { ReceivableAccount } from "./receivableAccount";
import { Attachment } from "./attachment";
import { User } from "./user";

export class ReceivableAccountAttachment extends DataType {
  id: number;
  accountId: number;
  receivableAccount: ReceivableAccount;
  attachmentId: number;
  attachment: Attachment;
  expirationDate: string;
  createdByUserId: number;
  createByUser: User;
  createdAt: string;
  updatedAt: string;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "accountId",
      "receivableAccount",
      "attachmentId",
      "attachment",
      "expirationDate",
      "createdByUserId",
      "createByUser",
      "createdAt",
      "updatedAt",
    ];
    super(object, properties);
  }
}
