import { DataType } from './dataType';
import { Address } from './address';
import { ContactType } from './contactType';

export class Contact extends DataType {
	id: number;
	typeId: number;
	type: ContactType;
	title: string;
	name: string;
	preferredName: string;
	address: Address;
	homePhone: string;
	cellPhone: string;
	workPhone: string;
	fax: string;
	email: string;

	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'typeId',
							'type',
							'title',
							'name',
							'preferredName',
							'address',
							'homePhone',
							'cellPhone',
							'workPhone',
							'fax',
							'email'];
		super(object, properties);

		if(object['address'] == undefined){
			this.address = new Address();
		}

		if(!object['type']){
			this.type = new ContactType();
		}
	}
}