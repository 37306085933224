import { DataType } from './dataType';

export class Opportunity extends DataType {
	id: number;
	description: string;
	opportunityTypeId: number;
	publishDate: string;
	bidOpeningDate: string;
	awardAmount: number;
	winningBidderBusinessId: number;
	status: string;
	createdAt: string;
	updatedAt: string;
	updatedByUserId: number;

	constructor(object: Object = {}){ 
		var properties = [
			'id',
			'description',
			'opportunityTypeId',
			'publishDate',
			'bidOpeningDate',
			'awardAmount',
			'winningBidderBusinessId',
			'status',
			'createdAt',
			'updatedAt',
			'updatedByUserId'
		];
		super(object, properties);
	}
}