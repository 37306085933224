import { DataType } from './dataType';
import { Contact } from './contact';
import { Business } from './business';
import { User } from './user';
import { State } from './state';
import { Country } from './country';

export class Customer extends DataType {
	id: number;
	customerNumber: string;
	contactId: number;
    contact: Contact;
    businessId: number;
    business: Business;
    status: string;
    name: string;
    address1: string;
    address2: string;
    city: string;
    stateId: number;
    state: State;
    countryId: number;
    country: Country;
    stateName: string;
    zipCode: string;
    phone: string;
    fax: string;
    email: string;
    createdAt: string;
    updatedAt: string;
    updatedByUserId: number;
    updateByUser: User

	constructor(object: Object = {}){ 
		var properties = [
							'id',
                            'customerNumber',
                            'contactId',
                            'contact',
                            'businessId',
                            'business',
                            'status',
                            'name',
                            'address1',
                            'address2',
                            'city',
                            'stateId',
                            'state',
                            'countryId',
                            'country',
                            'stateName',
                            'zipCode',
                            'phone',
                            'fax',
                            'email',
                            'createdAt',
                            'updatedAt',
                            'updatedByUserId',
                            'updatedByUser'];
		super(object, properties);

	}
}