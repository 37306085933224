import { Injectable } from '@angular/core';

import { 
	ResourceService,
	DataService
} from '../services/services';

@Injectable()
export class BusinessLicensesResource extends ResourceService {

	constructor(public dataService: DataService) { 
		super(dataService, 'businesseslicenseslist');
	}

	init(params) {
		this.url = "businesseslicenseslist";
	
		if (params.urlModifiers) {
		  let modifiers = params.urlModifiers;
	
		  if (modifiers.businessId) {
			this.url = "businesses/" + modifiers.businessId + "/licenses";
		  }

		  if(modifiers.userId){
			  this.url = "users/" + modifiers.userId + "/businesslicenses";
		  }
		}
	  }
}