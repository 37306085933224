import { DataType } from "./dataType";
import { User } from './user';

export class BusinessLicensePenaltyHistory extends DataType {
  id: number;
  penaltyMonth: Number;
  penaltyYear: Number;
  status: String;
  updatedByUserId: Number;
  updatedByUser: User;
  createdAt: String;
  updatedAt: String;
  feeCounts: Number;

  constructor(object: Object = {}) {
    var properties = [
        "id",
        "penaltyMonth",
        "penaltyYear",
        'status',
        "updatedByUserId",
        "createdAt",
        "updatedAt",
        "feeCounts"
    ];
    super(object, properties);
  }
}
