import { DataType } from './dataType';

export class Job extends DataType {
	id: number;
	name: string;

	constructor(object: Object = {}){ 
		var properties = [
            'id',
            'cityId',
            'city',
            'appId',
            'application',
            'jobType',
            'jobName',
            'jobStatus',
            'createdAt',
            'startedAt',
            'completedAt',
            'createdByUserId',
            'createdByUser',
            'messages',
        ];
		super(object, properties);
	}
}
