import { DataType } from "./dataType";
import { User } from "./user";
import { Customer } from "./customer";
import { ReceivableAccountType } from "./receivableAccountType";

export class ReceivableAccount extends DataType {
  id: number;
  accountNumber: string;
  accountTypeId: number;
  accountType: ReceivableAccountType;
  customerId: number;
  customer: Customer;
  startDate: string;
  endDate: string;
  status: string;
  lastBilledDate: string;
  lastBilledAmount: string;
  lastPaidDate: string;
  lastPaidAmount: string;
  pendingAmount: number;
  pastDueAmount: number;
  accountBalance: string;
  updatedByUserId: number;
  updatedByUser: User;
  createdAt: string;
  updatedAt: string;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "accountNumber",
      "accountTypeId",
      "accountType",
      "customerId",
      "customer",
      "startDate",
      "endDate",
      "status",
      "lastBilledDate",
      "lastBilledAmount",
      "lastPaidDate",
      "lastPaidAmount",
      "pendingAmount",
      "pastDueAmount",
      "accountBalance",
      "updatedByUserId",
      "updatedByUser",
      "createdAt",
      "updatedAt",
    ];
    super(object, properties);
  }
}
