import { DataType } from './dataType';
import { Address } from './address';
import { Location } from './location';
import { Contact } from './contact';

export class GarageSalePermit extends DataType {
	id: number;
	locationId: number;
	location: Location;
	organization: string;
	description: string;

	constructor(object: Object = {}){ 
		var properties = [
							'id',
							'locationId',
							'location',
							'organization',
							'description']
		super(object, properties);

		if(object['location']){
			this.location = new Location(object['location']);
		}
	}
}