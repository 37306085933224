import { DataType } from './dataType';
import { MeetingType } from './meetingType';
import { AttendeeRole } from './attendeeRole';
import { User } from './user';

export class MeetingTypeAttendee extends DataType {
  id: number;
  meetingTypeId: string;
  meetingType: MeetingType;
  attendeeId: number;
  attendee: User;
  attendeeRoleId: number;
  attendeeeRole: AttendeeRole;
  createdByUserId: string;
  createdByUser: User;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "meetingTypeId",
      "meetingType",
      "attendeeId",
      "attendee",
      "attendeeRoleId",
      "attendeeeRole",
      "createdByUserId",
      "createdByUser"
    ];
    super(object, properties);

  }
}
