import { DataType } from './dataType';
import { MeetingType } from './meetingType';
import { MeetingItemType } from './meetingItemType';
import { User } from './user';

export class MeetingTypeOutline extends DataType {
  id: number;
  meetingTypeId: string;
  meetingType: MeetingType;
  sequence: number;
  meetingItemTypeId: number;
  meetingItemType: MeetingItemType;
  name: string;
  createdByUserId: string;
  createdByUser: User;

  constructor(object: Object = {}) {
    var properties = [
      "id",
      "meetingTypeId",
      "meetingType",
      "sequence",
      "meetingItemTypeId",
      "meetingItemType",
      "name",
      "createdByUserId",
      "createdByUser"
    ];
    super(object, properties);

  }
}
