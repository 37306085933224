import { DataType } from './dataType';

export class Category extends DataType {
	id: number;
	name: string;

	constructor(object: Object = {}){ 
		var properties = ['id','name'];
		super(object, properties);
	}
}