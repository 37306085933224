import { Injectable } from '@angular/core';

import { 
	ResourceService,
	DataService
} from '../services/services';

@Injectable()
export class BusinessLicenseListResource extends ResourceService {
	
	constructor(public dataService: DataService) { 
		super(dataService, 'businesseslicenseslist');
	}
}