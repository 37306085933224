import { Injectable } from '@angular/core';

import { 
	ResourceService,
	DataService
} from '../services/services';

@Injectable()
export class PermitFeesResource extends ResourceService {

	constructor(public dataService: DataService) { 
		super(dataService, 'permits/:permitId/fees');
	}
}