import { Injectable } from '@angular/core';

import { 
	ResourceService,
	DataService
} from '../services/services';

@Injectable()
export class ApplicationsResource extends ResourceService {
	
	cacheData = true;
	
	constructor(public dataService: DataService) { 
		super(dataService, 'applications');
	}

	init(params){

		this.url = 'applications';
		
		if(params.urlModifiers){
			let modifiers = params.urlModifiers;

			if(modifiers.cityId){
				this.url = 'cities/:cityId/applicationsList';
			}
		}
	}
}